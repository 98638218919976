<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">text block with image grid</div>
        <div class="module-preview--contents">
            <div class="featured-images">
                <div class="image-wrapper" v-for="image in images" :key="image">
                    <img :src="image" alt="">
                </div>
            </div>            
            <div class="info-wrapper self-center">
                <div class="font-600 text-lg">{{ getValue('headlineOne') }}</div>
                <div class="font-500 text-base mt-1">{{ getValue('headlineTwo') }}</div>
                <p class="mt-4 text-sm" :inner-html.prop="truncate(getValue('content'), {length: 250})"></p>
            </div>
        </div>
    </div>
    
</template>

<script>
    import {mapGetters} from "vuex";
    import {truncate} from "lodash-es";
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                images: []
            }
        },
        computed: {
            ...mapGetters({
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId'
            })
        },
        mounted() {
            let imageIds = JSON.parse(this.getValue('images'));

            if(imageIds)
                imageIds.forEach( imageId => {
                    this.images.push(imageId);
                });
        },
        methods: {
            truncate,
        },
    }
</script>

<style scoped>
    .module-preview--contents{
        grid-template-columns: 1.5fr 1fr;
    }
    .module-preview--contents .featured-images{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 15px;
    }
    .module-preview--contents .image-wrapper{
        height: 100%;
        max-height: 250px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    } 
</style>